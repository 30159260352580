import {ApiBase} from '@jetCommon/jpe-api/base.js';

export default class INAILInsurancePositionApiCommon extends ApiBase {
    static resourceName = 'inail_insurance_positions';

    createCompletePAT(data) {
        return this.apiPost('create_complete_pat/', data);
    }

    updateCompletePAT(id, data) {
        return this.apiPost(`${id}/update_complete_pat/`, data);
    }

    deleteCompletePAT(id) {
        return this.apiPost(`${id}/delete_complete_pat/`);
    }
}
